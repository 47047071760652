<template>
  <div class="container">
    <div>
      <div class="search-container">
        <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
        >
          <el-form-item label="组名">
            <el-input v-model="formInline.groupname" placeholder="请输入组名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button v-indentify="'sys:group:view:add'" type="primary" icon="el-icon-plus" @click="handleGroup('add')">添加管理员组</el-button>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              prop="id"
              width="100"
              align="center"
              label="ID">
          </el-table-column>
          <el-table-column
              prop="pid"
              width="100"
              align="center"
              label="父级ID">
          </el-table-column>
          <el-table-column
              prop="name"
              label="组名">
          </el-table-column>
          <el-table-column
              label="权限ID">
            <template slot-scope="scope">
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                :content="scope.row.rules">
                <div slot="reference" class="ellipsis">{{ scope.row.rules }}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.createtime | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="200"
              align="center"
              label="操作">
            <template slot-scope="scope">
              <el-button v-indentify="'sys:group:view:edit'" type="primary" class="edit-btn" :underline="false" icon="el-icon-edit" size="small" @click="editGroup(scope.row.id)"
                       style="margin-right: 10px;">修改
              </el-button>
              <el-button v-indentify="'sys:group:view:del'" @click="deleteGroup(scope.row.id)" slot="reference" type="danger" class="edit-btn" icon="el-icon-delete" :underline="false" size="small"
                         style="margin-right: 10px;">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-drawer
        :title="drawerFlag === 'add' ? '添加管理员组' : '修改管理员组'"
        :visible.sync="groupDialog"
        @close="groupDialogClose"
        direction="rtl"
        size="50%">
      <el-form :model="groupForm" ref="groupForm" label-width="100px" class="dialog-form">
        <el-form-item label="管理员组ID" v-if="drawerFlag !== 'add'" prop="group_id">
          <el-input v-model="groupForm.group_id" disabled/>
        </el-form-item>
        <el-form-item label="父级" prop="pid">
          <el-select v-model="groupForm.pid" placeholder="请选择父级" @change="pidChange" style="width: 100%;" collapse-tags>
            <el-option
              v-for="item in groupData"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="groupForm.name"></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="rules">
          <div v-if="roleTree.length">
            <el-checkbox v-model="checkedAll" @change="checkedAllChange">选择全部</el-checkbox>
            <el-checkbox v-model="openAll" @change="checkedOpenAll">展开全部</el-checkbox>
          </div>
          <el-tree
            :data="roleTree"
            show-checkbox
            check-strictly
            node-key="id"
            ref="tree"
            :default-expand-all="openAll"
            check-on-click-node
            :expand-on-click-node="false">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                {{data.text}}
              </span>
            </span>
          </el-tree>
        </el-form-item>
        <div class="demo-drawer__footer">
          <el-form-item class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="confirmGroup()">确 定</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import {
  adminGroupDataApi,
  groupIndexApi,
  groupRoletreeApi,
  groupAddApi,
  groupEditApi,
  groupDelApi,
  groupInfoApi
} from '@/api/auth'
import { isArray } from '@/utils/validate'
import { _replaceNBSP, _toTree } from '@/utils/tools'
import Mixin from '@/utils/Mixin'

export default {
  name: 'PcGroupView',
  mixins: [Mixin],
  data () {
    return {
      formInline: {
        groupname: ''
      },
      groupForm: {
        pid: '',
        name: '',
        rules: [],
        group_id: '',
        status: 'normal'
      },
      groupData: [],
      groupDialog: false,
      tableData: [],
      drawerFlag: 'add',
      roleTree: [],
      roleTreeArray: [],
      openAll: true, // 默认展开所有节点
      checkedAll: false,
      roletreeForm: {
        group_id: '',
        group_pid: ''
      }
    }
  },
  async mounted () {
    await this.getList()
  },
  methods: {
    async getList () {
      const jsData = {
        ...this.formInline
      }
      const data = await groupIndexApi(jsData)
      if (data.code === 1) {
        this.tableData = _replaceNBSP(data.data, 'name')
        this.groupDialog = false
      }
    },
    /**
     * 权限展开全部选择框change事件
     */
    checkedOpenAll () {
      const treeNodes = this.$refs.tree.store._getAllNodes()
      treeNodes.forEach(item => item.expanded = this.openAll)
    },
    /**
     * 权限选择全部选择框change事件
     */
    checkedAllChange () {
      const setCheckedNodes = this.$refs.tree.setCheckedNodes
      if (this.checkedAll) {
        setCheckedNodes(this.roleTreeArray)
      } else {
        setCheckedNodes([])
      }
    },
    /**
     * 打开抽屉
     * @param {String} flag 当前操作的类型
     */
    handleGroup (flag) {
      this.getGroupData()
      this.groupDialog = true
      this.drawerFlag = flag
      if (flag === 'add') {
        this.resetGroupForm()
      }
    },
    /**
     * 关闭抽屉清空表单数据
     */
    groupDialogClose () {
      this.$refs.groupForm.resetFields()
      this.roleTree = []
      this.roleTreeArray = []
      this.roletreeForm = {
        group_id: '',
        group_pid: ''
      }
    },
    resetGroupForm () {
      this.groupForm = {
        pid: '',
        name: '',
        rules: [],
        group_id: '',
        status: 'normal'
      }
    },
    /**
     * 获取管理员组
     * @returns {Promise<void>}
     */
    async getGroupData () {
      const data = await adminGroupDataApi()
      if (data.code === 1) {
        this.groupData = _replaceNBSP(data.data, 'label')
      }
    },
    // 查询
    onSearch () {
      this.getList()
    },
    /**
     * 选择父级权限并动态修改权限树
     * @param {Number} group_pid 父级权限ID
     * @returns {Promise<void>}
     */
    async pidChange (group_pid) {
      this.roletreeForm.group_pid = group_pid
      const data = await groupRoletreeApi({ ...this.roletreeForm })
      if (data.code === 1) {
        if (isArray(data.data)) {
          this.defaultChecked(data.data)
          this.roleTree = _toTree(data.data, { pidKey: 'parent' })
          this.roleTreeArray = data.data
          this.checkedAll = false
          this.openAll = true
        } else {
          this.roleTree = []
          this.roleTreeArray = []
        }
      }
    },
    /**
     * 当前管理员组拥有的权限
     * @param {Array} groupRoletree 角色权限树
     */
    defaultChecked (groupRoletree) {
      // 过滤拥有的权限
      const selectedList = groupRoletree.filter(item => item.state.selected)
      // 默认选中
      this.$refs.tree.setCheckedKeys(selectedList.map(item => item.id))
    },
    /**
     * 修改管理员组
     * @param {Number} group_id 管理员组ID
     */
    async editGroup (group_id) {
      const data = await groupInfoApi({ group_id })
      this.handleGroup('edit')
      if (data.code === 1) {
        const { id, name, pid } = data.data
        this.roletreeForm.group_id = id
        this.groupForm.name = name
        this.groupForm.pid = pid
        this.groupForm.group_id = id
        this.pidChange(pid)
      }
    },
    /**
     * 删除管理员组
     * @param {Number} group_id 管理员组ID
     */
    async deleteGroup (group_id) {
      try {
        await this.$confirm('确定要删除此管理员组吗？')
        const data = await groupDelApi({ group_id })
        if (data.code === 1) {
          this.$message.success('删除管理员组成功')
          this.getList()
        }
      } catch (e) {

      }
    },
    /**
     * 关闭抽屉
     */
    cancelForm () {
      this.groupDialog = false
    },
    /**
     * 抽屉预处理
     */
    confirmGroup () {
      const { drawerFlag } = this
      if (drawerFlag === 'add') {
        this.groupAdd()
      } else {
        this.editGroupForm()
      }
    },
    /**
     * 修改管理员组表单方法
     * @returns {Promise<void>}
     */
    async editGroupForm () {
      const { groupForm, roleTreeArray } = this
      // 获取树形控件选中的ID
      const checkedKeys = this.$refs.tree.getCheckedKeys()
      // 获取树形空间选中的父级数据
      // const halfCheckedNodes = this.$refs.tree.getHalfCheckedNodes()
      // // 返回ID的数组
      // const halfCheckedNodesId = halfCheckedNodes.map(item => item.id)
      // // 合并并且过滤重复（应该不需要过滤）
      // const newCheckedKeys = Array.from(new Set([...checkedKeys, ...halfCheckedNodesId]))
      // 用，拼接成字符串
      groupForm.rules = checkedKeys.join(',')
      const data = await groupEditApi(groupForm)
      if (data.code === 1) {
        this.$message.success('修改管理员组成功')
        this.getList()
      }
    },
    /**
     * 添加管理员组
     * @returns {Promise<void>}
     */
    async groupAdd () {
      const { groupForm } = this.$data
      groupForm.rules = this.$refs.tree.getCheckedKeys().join(',')
      const data = await groupAddApi(groupForm)
      if (data.code === 1) {
        this.$message.success('添加管理员组成功')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
</style>
